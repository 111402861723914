@import "./variables.scss";

.section-wrapper {
  position: relative;
  padding: 4rem;
  margin-bottom: 20rem;

  @media (max-width: $viewport-size-mobile) {
    margin-bottom: 10rem;
  }

  > .title {
    padding-bottom: 6rem;
    margin: 0;
    font-size: $title-font-size-large;

    @media (max-width: $viewport-size-mobile) {
      padding-bottom: 0;
      font-size: $title-font-size-medium;
    }
  }

  &.reverse {
    background-color: $default-color;

    .title {
      color: $default-color-reverse;
    }
  }
}
