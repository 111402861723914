@import "./variables.scss";

.github-btn {
  $btn-size: 4rem;

  width: $btn-size;
  height: $btn-size;

  :hover {
    transition: 0.3s ease-in-out;
    transform: translateX($btn-size * -1 * 0.4) rotate(-40deg);
  }
}
