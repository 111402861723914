@import "./variables.scss";

.github-wrapper {
  $btn-size: 4rem;

  position: absolute;
  top: 4rem * 0.5;
  right: 0;
  transform: translateX($btn-size * 0.5);

  @media (max-width: $viewport-size-mobile) {
    display: none;
  }
}
