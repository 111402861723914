@import "./variables.scss";

.business-card {
  $width: 26rem;
  min-width: 15rem;
  $ratio: 55 / 85;

  position: relative;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  width: $width;
  height: $width * $ratio;
  color: $default-color-reverse;
  cursor: grab;

  &.flipped {
    .front {
      transform: rotateY(180deg);
    }
    .back {
      transform: rotateY(0deg);
    }
  }

  .front,
  .back {
    position: absolute;
    padding: 2rem;
    width: 100%;
    height: 100%;
    background-color: #302f34;
    box-shadow: 0px -6px 8px 0px rgba(#000, 0.1),
      0px 6px 8px 0px rgba(#000, 0.1), 6px 0px 8px 0px rgba(#000, 0.1),
      -6px 0px 8px 0px rgba(#000, 0.1);
    backface-visibility: hidden;
    perspective: 600px;
    transition: 1s;
  }

  .front {
    transform: rotateY(0deg);

    .layer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;

      h1 {
        font-size: $title-font-size-medium;
        font-weight: bold;
        letter-spacing: -0.1rem;
      }

      .corner {
        position: absolute;
        border-right: 0.3rem solid $default-color-reverse;
        border-top: 0.3rem solid $default-color-reverse;
        border-radius: 0.3rem;
        width: 0.8rem;
        height: 0.8rem;
        backface-visibility: hidden;

        &:nth-of-type(1) {
          top: 0;
          right: 0;
        }

        &:nth-of-type(2) {
          top: 0;
          left: 0;
          transform: rotateZ(-90deg);
        }

        &:nth-of-type(3) {
          bottom: 0;
          left: 0;
          transform: rotateZ(180deg);
        }

        &:nth-of-type(4) {
          right: 0;
          bottom: 0;
          transform: rotateZ(90deg);
        }
      }
    }
  }

  .back {
    transform: rotateY(-180deg);

    .title {
      padding-bottom: 4rem;
      font-size: $title-font-size-small;
    }

    .contents {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: baseline;

      .content {
        padding-bottom: 1rem;

        .sub-title {
          font-size: $title-font-size-small - 0.2rem;
          color: $secondary-color-light;
        }

        .sub-content {
          font-size: $content-font-size;

          a {
            color: $default-color-reverse;
          }
        }

        .email {
          cursor: copy;
        }

        .tooltip {
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 25%;
          padding: 0.2rem;
          border-radius: 1rem;
          margin: auto;
          width: 3rem;
          height: 1rem;
          font-size: 0.5rem;
          text-align: center;
          background-color: #{$default-color};

          &::after {
            content: "";
            position: absolute;
            border-width: 0.3rem;
            border-style: solid;
            border-color: #{$default-color} transparent transparent transparent;
            margin-left: -0.5rem;
            top: 100%;
            left: 50%;
          }
        }
      }
    }

    > .qr-code {
      position: absolute;
      top: 0;
      right: 3rem;
      bottom: 0;
      margin: auto 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      @media (max-width: $viewport-size-mobile) {
        display: none;
      }
    }
  }
}
