@import "./variables.scss";

.title-wrapper {
  display: flex;
  justify-content: center;

  .title-logo {
    width: 4rem;
    height: 4.5rem;
  }

  .title {
    padding-left: 0.5rem;
    font-weight: bold;

    > * {
      margin: 0;
      letter-spacing: -0.05rem;
    }

    :first-child {
      font-size: 1.5rem;
    }
    :nth-child(2) {
      font-size: 1.4rem;
    }
  }

  @media (max-width: $viewport-size-mobile) {
    padding-top: 4rem * 2;
  }
}
