@import "./variables.scss";

.work-wrapper {
  > .contents {
    position: relative;
    display: flex;
    flex-direction: column;
    width: inherit;
    height: 60vh;

    @media (max-width: $viewport-size-tablet) {
      padding: 4rem 0 4rem 0;
    }

    .works {
      display: flex;
      width: inherit;

      > * {
        left: 60vw;
        opacity: 0;
        transition: 1s;
        z-index: $z-index-disabled;
      }

      .selected {
        position: absolute;
        opacity: 1;
        left: 0;
        z-index: $z-index-tertiary;
      }
    }
    .controls-wrapper {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      height: calc(100% - 4rem);

      .controls {
        position: relative;
        height: 100%;

        .prev,
        .next {
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 0.1rem solid;
          width: 3rem;
          height: 3rem;
          font-size: 1.5rem;
          font-weight: bold;
          text-align: center;
          z-index: $z-index-tertiary;
          cursor: pointer;
          color: $main-color;
          background-color: $color-white;
          transition: 0.4s;

          &:hover {
            background-color: $main-color-light;
          }
        }

        .prev {
          left: -1.5rem;
        }

        .next {
          right: -1.5rem;
        }
      }
    }
  }
}
