@import "./variables.scss";

.btn {
  padding: 0.75rem 1rem;
  border: 0.1rem solid $main-color;
  border-radius: 0.2rem;
  color: $main-color;
  background-color: $color-white;
  font-size: 0.7rem;
  font-weight: bold;
  cursor: pointer;
  z-index: $z-index-tertiary;
  transition: 0.4s;

  &:hover {
    opacity: 1;
    background-color: $main-color-light;
    color: $main-color;
  }
}
