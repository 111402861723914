@import "./variables.scss";

.menu-wrapper {
  position: fixed;
  top: 4rem * 0.5 + 0.5;
  left: 4rem * 0.5;
  z-index: $z-index-primary + 1;

  .menu-icon {
    $menu-icon-transition: 0.5s;
    $menu-icon-top: 50%;

    position: relative;
    left: 0;
    right: 0;
    width: 2rem;
    height: 2rem;
    z-index: $z-index-primary + 1;
    cursor: pointer;
    transition: $menu-icon-transition;

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.2rem;
      background-color: $secondary-color;
      transition: $menu-icon-transition;
    }

    &::before {
      top: $menu-icon-top;
    }

    &::after {
      top: calc(#{$menu-icon-top} + 0.5rem);
    }

    &.close {
      $degree: 45deg;

      &::before {
        transform: rotate($degree);
      }
      &::after {
        transform: translate(0, -0.5rem) rotate($degree * -1);
      }
    }
  }

  .menu {
    $width: 25rem;

    position: fixed;
    top: 0;
    left: 0;
    width: $width;
    height: 100vh;
    background-color: $color-white;
    z-index: $z-index-primary;
    transform: translateX($width * -1);
    transition: 0.5s;

    &.show {
      transform: translateX(0);
    }

    ul {
      $font-size: 2.5rem;

      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      padding-left: 0;
      margin: auto;
      font-size: $font-size;
      text-align: center;
      list-style: none;

      li {
        opacity: 0.6;
        margin-top: $font-size;
        cursor: pointer;
        transition: 0.3s;

        &:first-child {
          margin-top: 0;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

#modal-root {
  .background {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $modal-background-color;
    z-index: $z-index-primary - 1;

    &.show {
      display: block;
    }
  }
}
