@import "./variables.scss";

.contact-wrapper {
  margin-bottom: 0;

  > .contents {
    margin-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $viewport-size-mobile) {
      padding: 4rem;
    }
  }
}
