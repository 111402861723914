@import "./variables.scss";

.work-card-wrapper {
  $card-height: 60vh;
  $card-margin-top: 4rem;
  $card-transition: 1s;

  position: relative;
  left: 0;
  right: 0;
  margin: $card-margin-top auto 0;
  border-radius: 1rem;
  width: 100%;
  max-width: $card-height * 2;
  height: $card-height;
  background-color: $color-white;
  box-shadow: 0 0 20px 8px #d0d0d0;
  font-size: 1rem;
  transition: $card-transition;

  @media (max-width: $viewport-size-mobile) {
    font-size: 0.8rem;
  }

  &:hover {
    height: $card-height * 1.2;

    .cover {
      opacity: 0.8;
      height: $card-height / 2.2;

      @media (max-width: $viewport-size-mobile) {
        width: 90%;
      }
    }

    .contents {
      visibility: visible;
      opacity: 1;
      padding-top: $card-height / 3;
      transition: $card-transition;
    }
  }

  .cover {
    position: absolute;
    top: $card-margin-top * -1;
    right: 0;
    left: 0;
    border: 0.1rem solid #{$color-white};
    border-radius: 1rem;
    margin: 0 auto;
    width: 90%;
    height: $card-height;
    transition: $card-transition;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    .cover-btn {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1rem;
    }

    .cover-text {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 1rem;
      margin: auto;
      width: fit-content;
      height: fit-content;
      color: $color-white;
      font-size: 1.5rem;
      text-align: center;
      text-shadow: 2px 3px 2px rgb(150 150 150 / 79%);
    }
  }

  .contents {
    $contents-padding: 1rem;

    width: calc(100% - 2 * #{$contents-padding});
    height: calc(100% - 2 * #{$contents-padding});
    padding: $contents-padding;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s ease-in-out;
    overflow: hidden;

    .content-item {
      display: none;
      position: relative;
      width: 100%;
      height: inherit;

      .title {
        padding: 0.5rem;
        margin: 0;
        width: 100%;
        font-size: 1.3rem;
        text-decoration: underline;
      }

      .items {
        padding: 1rem 1rem 1rem 2rem;
        width: calc(100% - 2 * 1rem);
        height: 45%;
        overflow-y: auto;

        .item {
          padding-bottom: 1rem;
          width: 100%;
          white-space: pre-wrap;
          word-break: keep-all;
          line-height: 2rem;

          @media (max-width: $viewport-size-mobile) {
            padding-bottom: 0.8rem;
          }
        }
      }

      &.show {
        display: flex !important;
        flex-direction: column;
      }
    }
  }

  .pagination {
    position: absolute;
    bottom: 0;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 2 * 1rem);
    color: $default-color-reverse;

    > ul {
      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 15rem;
      list-style: none;

      > li {
        border-radius: 50%;
        width: 1.1rem;
        height: 1.1rem;
        background-color: $default-color-reverse;
        cursor: pointer;

        &.active {
          border: 0.3rem solid;
          border-color: $main-color;
        }
      }
    }
  }

  &.disabled {
    z-index: $z-index-disabled !important;
  }
}
