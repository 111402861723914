@import "./variables.scss";

.card {
  position: relative;
  width: 15rem;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 0 20px 8px #d0d0d0;
  font-weight: bold;
  text-align: center;
  white-space: pre-wrap;
  background-color: $color-white;

  &.tooltips {
    cursor: pointer;

    @media (max-width: $viewport-size-mobile) {
      cursor: default;
    }
  }

  .tooltip-alert {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1rem;
    color: $default-color-reverse;
    background-color: $default-color;

    @media (max-width: $viewport-size-mobile) {
      display: none;
    }
  }

  .tooltip {
    opacity: 0;
    position: absolute;
    padding: 1rem;
    width: max-content;
    height: fit-content;
    font-size: 0.8rem;
    background-color: $color-white;
    box-shadow: 0 0 10px 4px #d0d0d0;
    z-index: $z-index-tertiary;
    cursor: default;
    transition: 0.6s;

    a {
      color: $link-color;

      &:hover {
        text-decoration: underline;
      }
    }

    &.show {
      opacity: 1;
    }

    &.left {
      top: -3rem;
      right: 20rem;
      border-radius: 1rem 1rem 0 1rem;
    }
    &.right {
      top: -3rem;
      left: 20rem;
      border-radius: 1rem 1rem 1rem 0;
    }
    &.bottom {
      top: 3rem;
    }

    @media (max-width: $viewport-size-mobile) {
      display: none;
    }
  }
}
