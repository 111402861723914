@import "./variables.scss";

// Font to Korean & Number
@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/OpenSans-Bold.ttf");
  src: url("/assets/fonts/OpenSans-Medium.ttf");
  unicode-range: U+26, U+0030-0039;
}

// Font to English
@font-face {
  font-family: "Noto Sans KR";
  src: url("/assets/fonts/NotoSansKR-Bold.otf");
  src: url("/assets/fonts/NotoSansKR-Medium.otf");
  unicode-range: U+AC00-D7A3;
}

html {
  // smoothing scroll
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  margin: 0;
  color: $default-color;
  background-color: $default-color-reverse;
  font-family: "Open Sans", "Noto Sans KR", sans-serif;
  font-size: $content-font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: $default-color;
}

::selection {
  color: $default-color-reverse;
  background-color: $default-color;
}

/* SCROLL */
*::-webkit-scrollbar {
  width: 0.8rem;
}
*::-webkit-scrollbar-track {
  background: $default-color-reverse;
}
*::-webkit-scrollbar-thumb {
  background-color: $color-white;
  border: 0.1rem solid #{$main-color};
  border-radius: 1rem;
}
