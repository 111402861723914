@import "./variables.scss";

.about-me-wrapper {
  > .contents {
    padding-bottom: 6rem;
    display: flex;
    justify-content: center;
    filter: grayscale(1) opacity(0.8);
    transition: 0.8s;

    &:hover {
      filter: grayscale(0) opacity(1);
    }

    @media (max-width: $viewport-size-tablet) {
      padding-bottom: 4rem;
      flex-direction: column;
      align-items: center;
    }

    .skills-wrapper {
      padding-left: 5rem;
      width: 50%;
      height: 25rem;

      @media (max-width: $viewport-size-tablet) {
        padding-left: 0;
        width: 100%;
      }

      .skills-scene {
        background-color: transparent;
      }
    }

    > .content {
      font-weight: bold;
      line-height: 3rem;
      white-space: pre-wrap;
      word-break: keep-all;
      text-align: center;
      background-image: url("/assets/images/about-bg.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      overflow: hidden;

      @media (max-width: $viewport-size-tablet) {
        padding: 4rem 0 4rem 0;
      }

      @media (max-width: $viewport-size-mobile) {
        background: none;
      }
    }
  }

  > .sub-contents {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 100%;

    @media (max-width: $viewport-size-tablet) {
      flex-direction: column;
      flex-wrap: nowrap;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 50%;
      text-align: center;

      > * {
        margin-bottom: 2rem;
      }

      > :first-child {
        border-bottom: 0.1rem solid;
        display: inline;
        font-size: $title-font-size-small;
      }
    }
  }
}
