@import "./variables.scss";

.fade-in-wrapper {
  position: relative;

  .fade-in {
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $default-color-reverse;
    z-index: $z-index-secondary;
    transition: transform $delay-fade-in ease-in-out,
      opacity $delay-fade-in * 10;

    &.show {
      opacity: 0;

      &.right {
        transform: translateX(100rem);
      }

      &.left {
        transform: translateX(-100rem);
      }
    }

    &.delay-1 {
      transition-delay: 1s;
    }
    &.delay-2 {
      transition-delay: 2s;
    }
    &.delay-3 {
      transition-delay: 3s;
    }
    &.delay-4 {
      transition-delay: 4s;
    }
  }
}
