@import "./variables.scss";

footer {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  color: $secondary-color;
  background-color: $default-color;

  @media (max-width: $viewport-size-tablet) {
    font-size: 0.8rem;
  }

  @media (max-width: $viewport-size-mobile) {
    border-top: 1px solid #{$secondary-color};
    flex-direction: column;
    justify-content: center;
    line-height: 1.4rem;
  }

  > .line {
    width: 65%;
    height: 0.1rem;
    background-color: $secondary-color;

    @media (max-width: $viewport-size-tablet) {
      width: 50%;
    }

    @media (max-width: $viewport-size-mobile) {
      display: none;
    }
  }

  > .right {
    :last-child {
      padding-left: 1rem;
      color: $default-color-reverse;
      font-weight: bold;

      @media (max-width: $viewport-size-mobile) {
        padding-left: 0.5rem;
      }
    }
  }
}
