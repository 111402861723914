@import "./variables.scss";

.visual-wrapper {
  position: relative;
  padding: 4rem;
  margin-bottom: 20rem;
  height: 90vh;

  @media (max-width: $viewport-size-mobile) {
    margin-bottom: 10rem;
  }

  .text-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: fit-content;
    height: fit-content;
    font-size: 3rem;
    font-weight: bold;
    font-style: italic;
    line-height: 4rem;
    white-space: pre-wrap;
    color: $default-color-reverse;

    @media (max-width: $viewport-size-tablet) {
      font-size: 2rem;
      line-height: 3rem;
    }

    @media (max-width: $viewport-size-mobile) {
      font-size: 1.3rem;
      line-height: 2.3rem;
      text-align: center;
    }
  }

  .visual {
    position: absolute !important;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 90%;
    height: inherit !important;
    background-color: transparent;

    @media (max-width: $viewport-size-mobile) {
      max-width: 70vh;
      max-height: 50vh;
    }
  }

  @media (max-width: $viewport-size-mobile) {
    max-width: 70vh;
    max-height: 50vh;
  }
}
