$viewport-size-tablet: 1024px;
$viewport-size-mobile: 600px;

$color-white: #fdfdfd;
$main-color: #e9897e;
$main-color-light: #f6d0cb;
$default-color: #141414;
$default-color-reverse: #f0f0f0;
$secondary-color: #302f34;
$secondary-color-light: #908f92;
$modal-background-color: rgba(0, 0, 0, 0.5);
$link-color: #4178be;

$title-font-size-large: 3.5rem;
$title-font-size-medium: 2rem;
$title-font-size-small: 1.3rem;

$content-font-size: 1.1rem;

$z-index-primary: 30;
$z-index-secondary: 20;
$z-index-tertiary: 10;
$z-index-disabled: -1;

$delay-fade-in: 2s;
