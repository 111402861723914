@import "./variables.scss";

header {
  position: relative;
  padding: 4rem;
  padding-top: 4rem * 0.5;
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $viewport-size-mobile) {
    padding: 1rem;
  }
}
